import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	isBlank,
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

const DEFAULT_SEARCH = {
	adults: 2,
	children: 0,
	babys: 0,
	petsCount: 0,
	from: undefined,
	till: undefined,
	name: undefined,
	region: '',
	bedrooms_min: '',
	bathrooms_min: '',
	type: ''

};


export default {
	data: function () {
		return {
			units: []
		};
	},

	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
		var savedUnits = JSON.parse(localStorage.getItem('lastVisitedUnits_' + suffix));
		var count = 0;
		var unitsIds = [];
		if (savedUnits) {
			count = Object.keys(savedUnits).length;
		}


		if (count > 0) {
			for (var index = 0; index < count; index++) {
				unitsIds.push(savedUnits[index]._id);
			}

			var req = {
				fields: this.resultFields,
				filter: {

				},
				ids: unitsIds
			};

			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;

				});

		}

	},
	updated: function () {
		//this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		show: function () {
			if (this.units.length > 0) {
				return true;
			}
			return false;
		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
		getUnitSearchPath: function (path, o) {
			var sd = this.searchData;

			var searchKeys = ['from', 'till', 'adults', 'children', 'babys', 'pets', 'petsCount'];

			var params = [];
			for (let sp of searchKeys) {
				if (o && o[sp]) {
					params.push(sp + '=' + o[sp]);
				} else if (!isBlank(sd[sp])) {
					params.push(sp + '=' + sd[sp]);
				}
			}

			if (params.length) {
				path += '#?' + params.join('&');
			}
			return path;


		},


		applySlider: function () {
			$(".owl-carousel.lastviewed-units").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},

					768: {
						items: 2
					},
					1200: {
						items: 3

					}
				}
			});
		}
	}

};