<template>
    <div>

        <div v-html="makeSeasonsRentalPrices()">

        </div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			groupedSeasons: [],
    			serviceByFinalCleaning: [],
    			priceListFilteredByRent: [],
    			priceListFilteredByTax: [],
    			serviceByBookingFee: [],
    			serviceByBettwaesche: [],
    			groupedSeasonsWithPrice: []

    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		//console.log('Pricelist::', pricelist);


    		/**
    		 * 1. filter all elements, which are of type 'RENT', are no surcharge and element has lines array
    		 */
    		const priceListFilteredByRent = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'RENT' && !priceListElement.service.surcharge && priceListElement.lines;
    		});

    		this.priceListFilteredByRent = priceListFilteredByRent;
    		//console.log('priceListFilteredByRent', priceListFilteredByRent);

    		if (priceListFilteredByRent) {
    			const priceListFilteredByRentLines = priceListFilteredByRent[0].lines;
    			// console.log('priceListFilteredByRentLines', priceListFilteredByRentLines);

    			/**
    			 * 2. reduce lines of filtered elements to only needed properties
    			 */
    			const priceListFilteredByRentMappedLines = priceListFilteredByRentLines.map(({
    				season, calculation, condition, fromdate, tilldate, value
    			}) => {
    				return {
    					season, calculation, condition, fromdate, tilldate, value
    				};
    			});
    			// console.log('priceListFilteredByRentMappedLines', priceListFilteredByRentMappedLines);

    			/**
    			 * 3. get grouped season of line elements
    			 */

    			/**
    			 * 3.1 get unique season names
    			 */
    			let seasonNames = [];
    			priceListFilteredByRentMappedLines.forEach(({
    				season
    			}) => {
    				if (!seasonNames.includes(season)) {
    					seasonNames.push(season);
    				}
    			});
    			// console.log('seasonNames', seasonNames);

    			/**
    			 * 3.2 group season periods for seasons
    			 */
    			let groupedSeasons = [];
    			seasonNames.forEach((seasonName) => {
    				const linesBySeasonName = priceListFilteredByRentMappedLines.filter(({
    					season
    				}) => {
    					return season === seasonName;
    				});
    				// console.log('linesBySeasonName', linesBySeasonName);

    				let seasonWithPeriods = {
    					name: seasonName,
    					seasonPeriods: []
    				};
    				linesBySeasonName.forEach(({
    					fromdate, tilldate
    				}) => {
    					const fromdateFormatted = new Date(fromdate).toLocaleDateString('de-DE', {
    						year: 'numeric',
    						month: '2-digit',
    						day: '2-digit'
    					});
    					const tilldateFormatted = new Date(tilldate).toLocaleDateString('de-DE', {
    						year: 'numeric',
    						month: '2-digit',
    						day: '2-digit'
    					});

    					const period = `${fromdateFormatted} - ${tilldateFormatted}`;
    					seasonWithPeriods.seasonPeriods.push(period);
    				});
    				if (seasonWithPeriods.seasonPeriods) {
    					groupedSeasons.push(seasonWithPeriods);
    				}
    			});

    			this.groupedSeasons = groupedSeasons;
    			//console.log('groupedSeasons', groupedSeasons);

    			/**
    			 * 4 filter cleaningprices
    			 */

    			const priceListFilteredByFinalCleaning = pricelist.filter((filterListItem) => {
    				return filterListItem.service.type && filterListItem.service.type === 'FINALCLEANING' && filterListItem.lines;
    			});

    			//console.log('priceListFilteredByFinalCleaning', priceListFilteredByFinalCleaning);

    			const serviceByFinalCleaning = priceListFilteredByFinalCleaning.map((service) => {
    				return {
    					lines: service.lines.map(({
    						calculation, condition, fromdate, tilldate, value
    					}) => {
    						return {
    							calculation, condition, fromdate, tilldate, value
    						}
    					}),
    					serviceName: service.service.name,
    					price: service.price
    				}
    			});
    			this.serviceByFinalCleaning = serviceByFinalCleaning;
    			//console.log('serviceByFinalCleaning', serviceByFinalCleaning);

    			const priceListFilteredByBookingFee = pricelist.filter((filterListItem) => {
    				return filterListItem.service.type && filterListItem.service.type === 'BOOKING_FEE';
    			});
    			//console.log('priceListFilteredByBookingFee ', priceListFilteredByBookingFee);


    			const priceListFilteredByTax = pricelist.filter((priceListElement) => {
    				return priceListElement.service.type && priceListElement.service.type === 'VTAX' && !priceListElement.service.surcharge && priceListElement.lines;
    			});
    			this.priceListFilteredByTax = priceListFilteredByTax;
    			const serviceByTax = priceListFilteredByTax.map((service) => {
    				return {
    					lines: service.lines.map(({
    						calculation, condition, fromdate, tilldate, value
    					}) => {
    						return {
    							calculation, condition, fromdate, tilldate, value
    						}
    					}),
    					serviceName: service.service.name,
    					price: service.price
    				}
    			});

    			//console.log('serviceByTax ', serviceByTax);

    			const serviceByBookingFee = priceListFilteredByBookingFee.map((element) => {
    				return {
    					calculation: element.service.calculation,
    					name: element.service.name,
    					perAdult: element.service.perAdult,
    					perBaby: element.service.perBaby,
    					perBeds: element.service.perBeds,
    					perChild: element.service.perChild,
    					price: element.price
    				}
    			});

    			this.serviceByBookingFee = serviceByBookingFee;

    			const priceListFilteredByBettwaesche = pricelist.filter((filterListItem) => {
    				return filterListItem.service._id === 33333;
    			});

    			//console.log('priceListFilteredByBettwaesche ', priceListFilteredByBettwaesche);

    			const serviceByBettwaesche = priceListFilteredByBettwaesche.map((element) => {
    				return {
    					calculation: element.service.calculation,
    					name: element.service.name,
    					perAdult: element.service.perAdult,
    					perBaby: element.service.perBaby,
    					perBeds: element.service.perBeds,
    					perChild: element.service.perChild,
    					price: element.price
    				}
    			});

    			this.serviceByBettwaesche = serviceByBettwaesche;

    			//console.log('serviceByBookingFee ', serviceByBookingFee);

    			this.makeSeasonsRentalPrices();


    		}

    	},
    	methods: {

    		makeSeasonsRentalPrices: function() {
    			let groupedSeasons = this.groupedSeasons;
    			let serviceByFinalCleaning = this.serviceByFinalCleaning;

    			let groupedSeasonsWithPrice = [];

    			var weeklyRentalPrice = 0;
    			var weeklyTaxPrice = 0;
    			var bookingFeePrice = 0;
    			var cleaningPrice = 0;
    			var bettwaeschePrice = 0;
    			var weeklyTotalPrice = 0;
    			var i = -1;
    			var entry = {};
    			groupedSeasons.forEach((season) => {
    				i++;
    				//console.log(i);
    				weeklyRentalPrice = this.calculateRentalPrice(season.name);
    				//console.log('___________________________');
    				//console.log(season.name);
    				//console.log('weeklyRentalPrice:', weeklyRentalPrice);
    				weeklyTaxPrice = this.calculateTaxPrice(season.name);
    				//console.log('weeklyTaxPrice:', weeklyTaxPrice);
    				cleaningPrice = this.calculateCleaningPrice(season.seasonPeriods);
    				//console.log('cleaningPrice:', cleaningPrice);
    				bookingFeePrice = this.calculateBookingFeePrice(season.seasonPeriods);
    				//console.log('bookingFeePrice:', bookingFeePrice);
    				bettwaeschePrice = this.calculateBettwaeschePrice(season.seasonPeriods);
    				//console.log('bettwaeschePrice:', bettwaeschePrice);
    				weeklyTotalPrice = weeklyRentalPrice + weeklyTaxPrice + cleaningPrice + bookingFeePrice + bettwaeschePrice;
    				//console.log('weeklyTotalPrice:', weeklyTotalPrice);
    				//console.log('___________________________');

    				entry = {
    					'name': season.name,
    					'seasonPeriods': season.seasonPeriods,
    					'price': weeklyTotalPrice / 100
    				}

    				groupedSeasonsWithPrice.push(entry);


    			});



    			//this.groupedSeasonsWithPrice = groupedSeasonsWithPrice;

    			//console.log('groupedSeasonsWithPrice', this.groupedSeasonsWithPrice);

    			return this.makeSeasonTable(groupedSeasonsWithPrice);

    		},



    		calculateRentalPrice: function(seasonName) {
    			var weeklyRentalPrice = 0;
    			if (this.priceListFilteredByRent[0].lines) {
    				var lines = this.priceListFilteredByRent[0].lines;
    				for (let i = 0; i < lines.length; i++) {
    					if (lines[i].season === seasonName) {
    						//console.log('season::' + lines[i].season);
    						//console.log('DayPrice', lines[i].value);
    						if (weeklyRentalPrice < (lines[i].value * 7)) {
    							weeklyRentalPrice = lines[i].value * 7;
    						}
    						//return (lines[i].value * 7);
    					}

    				}
    			}
    			return weeklyRentalPrice;
    		},

    		calculateTaxPrice: function(seasonName) {
    			var weeklyRentalPrice = 0;
    			//console.log('this.priceListFilteredByTax::' + this.priceListFilteredByTax);
    			if (this.priceListFilteredByTax && this.priceListFilteredByTax[0] && this.priceListFilteredByTax[0].lines) {
    				var lines = this.priceListFilteredByTax[0].lines;
    				for (let i = 0; i < lines.length; i++) {
    					if (lines[i].season === seasonName) {
    						//console.log('season::' + lines[i].season);
    						//console.log('DayPrice', lines[i].value);
    						if (weeklyRentalPrice < (lines[i].value * 7)) {
    							weeklyRentalPrice = lines[i].value * 7;
    						}
    						//return (lines[i].value * 7);
    					}

    				}
    			}
    			return weeklyRentalPrice;
    		},

    		calculateCleaningPrice: function(seasonPeriods) {
    			//console.log('calculateCleaningPrice 1111111111111111111111111');
    			if (this.serviceByFinalCleaning[0]) {
    				//console.log('this.serviceByFinalCleaning 2222222222222222222222222222');
    				var data = this.serviceByFinalCleaning[0];
    				if (data.price) {
    					//console.log('data.price 33333333333333333333333333');
    					return data.price;
    				} else {
    					var dates = seasonPeriods[0].split(' - ');
    					var startDate = dates[0].split('.');
    					var prices = [];

    					console.log('seasonPeriod:', startDate[2]);

    					if (data.lines) {
    						for (let i = 0; i < data.lines.length; i++) {
    							var fromdate = data.lines[i].fromdate.split('-');
    							console.log('fromdate:', fromdate[0]);
    							if (fromdate[0] === startDate[2]) {
    								prices.push(data.lines[i].value);
    								console.log('CleaningPrice::', data.lines[i].value);
    								return prices[0];
    							} else if (fromdate[0] <= startDate[2]) {
    								prices.push(data.lines[i].value);
    							}

    						}
    						return prices[0];


    					}

    				}
    			}
    		},

    		calculateTax: function(seasonPeriods) {
    			console.log('calculateCleaningPrice 1111111111111111111111111');
    			if (this.serviceByFinalCleaning[0]) {
    				console.log('this.serviceByFinalCleaning 2222222222222222222222222222');
    				var data = this.serviceByFinalCleaning[0];
    				if (data.price) {
    					console.log('data.price 33333333333333333333333333');
    					return data.price;
    				} else {
    					var dates = seasonPeriods[0].split(' - ');
    					var startDate = dates[0].split('.');
    					var prices = [];

    					console.log('seasonPeriod:', startDate[2]);

    					if (data.lines) {
    						for (let i = 0; i < data.lines.length; i++) {
    							var fromdate = data.lines[i].fromdate.split('-');
    							console.log('fromdate:', fromdate[0]);
    							if (fromdate[0] === startDate[2]) {
    								prices.push(data.lines[i].value);
    								console.log('CleaningPrice::', data.lines[i].value);
    								return prices[0];
    							} else if (fromdate[0] <= startDate[2]) {
    								prices.push(data.lines[i].value);
    							}

    						}
    						return prices[0];


    					}

    				}
    			}
    		},

    		calculateBookingFeePrice: function(seasonPeriods) {
    			if (this.serviceByBookingFee[0]) {
    				var data = this.serviceByBookingFee[0];
    				if (data.calculation && data.calculation === 'FLAT') {
    					if (data.price) {
    						return data.price;
    					}
    				} else if (data.calculation && data.calculation === 'NIGHT') {
    					if (data.price) {
    						return (data.price * 7);
    					}
    				}

    			}
    		},

    		calculateBettwaeschePrice: function(seasonPeriods) {
    			if (this.serviceByBettwaesche[0]) {
    				var data = this.serviceByBettwaesche[0];
    				if (data.calculation && data.calculation === 'FLAT') {
    					if (data.price) {
    						return data.price;
    					}
    				} else if (data.calculation && data.calculation === 'NIGHT') {
    					if (data.price) {
    						return (data.price * 7);
    					}
    				}

    			}
    		},

    		makeSeasonTable: function(groupedSeasonsWithPrice) {
    			let data = groupedSeasonsWithPrice;
    			//console.log('data::', this.groupedSeasonsWithPrice);
    			var str = '';
    			str += '<table class="table pricelist table-striped">';
    			str += '<thead>';
    			str += '<tr>';
    			str += '<th scope="col">Saison</th>';
    			str += '<th scope="col">Zeiten</th>';
    			str += '<th scope="col">z.B. Preis für eine Woche inklusive Bettwäsche und Kurtaxe für einen Gast</th>';
    			str += '</tr>';
    			str += '</thead>';

    			data.forEach((line) => {
    				str += '<tr>';
    				str += '<td>' + line.name + '</td>';
    				str += '<td>';
    				if (line.seasonPeriods) {
    					line.seasonPeriods.forEach((period) => {
    						str += period + '<br/>';
    					});
    				}
    				str += '</td>';

    				str += '<td>ab ' + line.price.toFixed(2).replace(".", ",") + ' &euro;';
    				str += '<br/><span class="small">Inklusive Endreinigung, Bettwäsche bezogen, Kurtaxe</span>';
    				str += '</td>';
    				str += '</tr>';

    			});

    			str += '</table>';

    			return str;
    		}


    	},
    	computed: {

    	}
    };
</script>